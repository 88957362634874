import React, { useContext, useRef, useState } from "react";
import styles from "./slider.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./bannerarrow.css";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Button } from "@mui/material";
import { UserCredsContext } from "../../../ContextApi/UserCredsContext/UserCredsContext";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";

const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  // alignItems:"center"
};

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <button
      className="custom-arrow-banner custom-prev-arrow-banner"
      onClick={onClick}
    >
      <ArrowBackIosIcon /> {/* Material-UI ArrowBack icon */}
    </button>
  );
}

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      className="custom-arrow-banner custom-next-arrow-banner"
      onClick={onClick}
    >
      <ArrowForwardIosIcon /> {/* Material-UI ArrowForward icon */}
    </button>
  );
}
export default function BannerSlider(values) {
  const { setDisplayType, setOpenModel, user } = useContext(UserCredsContext);
  const [testType, setTestType] = useState(null);
  const history = useHistory();
  const playerRef = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [finelOpne, setFinelOpne] = useState(false);

  var settings = {
    className: "",
    // dots: true,
    // autoplay: true,
    // infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // arrows: false,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    // customPaging: (i) => (
    //   <div
    //     style={{
    //       borderBottom: "4px solid #12888A66 ",
    //       backgroundColor: "#12888A66",
    //       // padding: "0px 80px",
    //     }}
    //     className="update-slick"
    //   ></div>
    // ),
  };

  const redirects = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/testseries");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };

  const data = values?.values;

  console.log(values?.values, "sdhgajdhgajfdakdasd");

  const viewVideoOpne = (video) => {
    setSelectedVideo(video);
    setFinelOpne(true);
  };
  const viewVideoClose = () => setFinelOpne(false);
  return (
    <>
      <Slider {...settings}>
        {/* {data?.map((v) => {
          return (
            <>
              <section className={styles.HomeBanner}>
                <div
                  className={`${styles.backgroundBanner} ${styles.hidemobile}`}
                >
                  <img src={v?.img} alt="banner" />
                </div>
                <div
                  className={`${styles.backgroundBanner} ${styles.showmobile}`}
                >
                  <img src={v?.imgmobile} alt="banner" />
                </div>
                <div className={styles.bannerTexts}>
                  <h1 className={styles.bannertagLine}>{v?.headingtext}</h1>
                  <p className={styles.bannerpara}>
                    {v?.bannerpara}
                    <span> {v?.highlight}</span>.
                  </p>
                  <Button
                    variant="contained"
                    className={styles.btnGradient}
                    onClick={() => {
                      redirects();
                    }}
                  >
                    Take the Test
                    <ArrowForward />
                  </Button>
                </div>
              </section>
            </>
          );
        })} */}
        <div
          className={`${styles.mainBannerContainer} ${styles.bannercolorfour} ${styles.flex}`}
        >
          <div className={`${styles.textcontainer}`}>
            <h1 className={`${styles.bannertagLine} ${styles.gradiantfont}`}>
              {data[4]?.headingtext}
            </h1>
            <p className={`${styles.bannerpara} ${styles.textblue}`}>
              {data[4]?.bannerpara}
              <span> {data[4]?.highlight}</span>
            </p>
            <Button
              variant="contained"
              className={styles.btnGradient}
              onClick={() => {
                redirects();
              }}
            >
              REQUEST DEMO
              <ArrowForward />
            </Button>
          </div>
          <div className={`${styles.leftsideContainer}`}>
          <div className={`${styles.videocontainer}`}>
            <ReactPlayer
              controls={true}
              ref={(player) => {
                playerRef.current = player;
              }}
              // url={selectedVideo?.file}
              url="https://youtu.be/kxF-7NECgio?si=cz7mqceKQ8JUPSb7"
              width="100%"
              height="300px"
              borderRadius="25px"
              className={styles.innerVideoDisplay}
              style={{
                // margin: "50px auto",
                backgroundColor: "#000",
                borderRadius: "25px",
              }}
            />
          </div>
          {/* <button className={`${styles.ViewMoreButton}`} onClick={() => viewVideoOpne()}>Watch full video here</button> */}
          </div>
        </div>
        <div
          className={`${styles.mainBannerContainer} ${styles.banneronecolor} ${styles.flex}`}
        >
          <div className={`${styles.textcontainer}`}>
            <h1 className={`${styles.bannertagLine} ${styles.gradiantfont}`}>
              {data[1]?.headingtext}
            </h1>
            <p className={`${styles.bannerpara} ${styles.textblue}`}>
              {data[1]?.bannerpara}
              {/* <span> {data[0]?.highlight}</span>. */}
            </p>
            <Button
              variant="contained"
              className={styles.btnGradient}
              onClick={() => {
                redirects();
              }}
            >
              REQUEST DEMO
              <ArrowForward />
            </Button>
          </div>
          <div className={`${styles.imgcontainer} ${styles.margintop40}`}>
            <img src={data[0]?.img} alt="" />
          </div>
        </div>
        <div
          className={`${styles.mainBannerContainer} ${styles.bannercolortwo} ${styles.flex}`}
        >
          <div className={`${styles.textcontainer}`}>
            <h1 className={`${styles.bannertagLine} ${styles.gradiantfont}`}>
              {data[2]?.headingtext}
            </h1>
            <p className={`${styles.bannerpara} ${styles.textblue}`}>
              {data[2]?.bannerpara}
              {/* <span> {data[0]?.highlight}</span>. */}
            </p>
            <Button
              variant="contained"
              className={styles.btnGradient}
              onClick={() => {
                redirects();
              }}
            >
              REQUEST DEMO
              <ArrowForward />
            </Button>
          </div>
          <div className={`${styles.imgcontainerforiit}`}>
            <img src={data[2]?.img} alt="" />
          </div>
        </div>
        {/* <div
          className={`${styles.mainBannerContainer} ${styles.bannercolortwo} ${styles.textcenter}`}
        >
          <div className={`${styles.textcontainer} ${styles.marginauto}`}>
            <h1 className={`${styles.bannertagLine} ${styles.bannertwopurple}`}>
              {data[0]?.headingtext}
            </h1>
            <p className={`${styles.bannerpara} ${styles.bannertwopara}`}>
              {data[0]?.bannerpara}
              <span> {data[0]?.highlight}</span>.
            </p>
            <Button
              variant="contained"
              className={styles.btnGradient}
              onClick={() => {
                redirects();
              }}
            >
              REQUEST DEMO
              <ArrowForward />
            </Button>
          </div>
          <div className={`${styles.imgcontainer} ${styles.marginauto}`}>
            <img src={data[1]?.img} alt="" />
          </div>
        </div> */}
        <div
          className={`${styles.mainBannerContainer} ${styles.bannercolorthree} ${styles.flex}`}
        >
          <div className={`${styles.textcontainer}`}>
            <h1 className={`${styles.bannertagLine} ${styles.gradiantfont}`}>
              {data[3]?.headingtext}
            </h1>
            <p className={`${styles.bannerpara} ${styles.textblue}`}>
              {data[3]?.bannerpara}
              {/* <span> {data[0]?.highlight}</span> */}
            </p>
            <Button
              variant="contained"
              className={styles.btnGradient}
              onClick={() => {
                redirects();
              }}
            >
              REQUEST DEMO
              <ArrowForward />
            </Button>
          </div>
          <div className={`${styles.imgcontainer}`}>
            <img src={data[3]?.img} alt="" />
          </div>
        </div>
        
      </Slider>

      <Modal
        open={finelOpne}
        onClose={viewVideoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...styleTwo, width:'80%'}} className={styles.boxForMobile}>
          <div
            className={styles.closebtn}
            style={{ textAlign: "right", cursor: "pointer" }}
          >
            <CloseIcon onClick={viewVideoClose} />
          </div>
          <div className={styles.videoModel}>
            <ReactPlayer
              controls={true}
              ref={(player) => {
                playerRef.current = player;
              }}
              // url={selectedVideo?.file}
              url="https://youtu.be/kxF-7NECgio?si=cz7mqceKQ8JUPSb7"
              // url="https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8"
              width="100%"
              height="500px"
              className={styles.innerVideoDisplay}
              style={{
                margin: "0 auto",
                backgroundColor: "#000",
              }}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}
