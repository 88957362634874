import React, { useRef } from "react";
import styles from "./index.module.css";
import bannerpc from "../../Assets/newevopage/evoBanner-desktop.png";
import bannermobile from "../../Assets/newevopage/evoBannermobile.png";
import backArrow from "../../Assets/newevopage/bannerbackarrow.png";
import EvoCardWWD from "../../Components/Newdesigncomponets/EvoCardWWD/EvoCardWWD";
import wwdimg1 from "../../Assets/newevopage/evowwd-1.png";
import wwdimg2 from "../../Assets/newevopage/evowwd-2.png";
import wwdimg3 from "../../Assets/newevopage/evowwd-3.png";
import wwdimg4 from "../../Assets/newevopage/evowwd-4.png";
import wwdimg5 from "../../Assets/newevopage/evowwd-5.png";
import TextimgCard from "../../Components/Newdesigncomponets/TextimgCard/TextimgCard";
import course1 from "../../Assets/newevopage/Coding.png";
import course2 from "../../Assets/newevopage/Robotics.png";
import course3 from "../../Assets/newevopage/Drone.png";
import course4 from "../../Assets/newevopage/Python.png";
import course5 from "../../Assets/newevopage/AR-VR.png";
import course6 from "../../Assets/newevopage/HTML.png";
import course7 from "../../Assets/newevopage/Electronics.png";
import course8 from "../../Assets/newevopage/3D design and Printing.png";
import tools1 from "../../Assets/newevopage/PictoBlox.png";
import tools2 from "../../Assets/newevopage/Notepad ++.png";
import tools3 from "../../Assets/newevopage/Oneshape.png";
import tools4 from "../../Assets/newevopage/MIT APP Inventor.png";
import tools5 from "../../Assets/newevopage/Jupyter.png";
import tools6 from "../../Assets/newevopage/Unity.png";
import { Button } from "@material-ui/core";
import LeftRightcomponent from "../../Components/Newdesigncomponets/LeftRightComponent/LeftRightcomponent";
import whyus1 from "../../Assets/newevopage/whyus-1.png";
import whyus2 from "../../Assets/newevopage/whyus-2.png";
import outcome from "../../Assets/newevopage/outcome-Img.png";
import ExplorMore from "../../Components/Newdesigncomponets/ExploreMore/ExplorMore";
import StemAccredition from "../../Assets/Stem-Accredition .png";
import BuyKitCard from "../../Components/Newdesigncomponets/BuykitCard/BuyKitCard";
import buykitimg from "../../Assets/newevopage/buykitimg.png";
import ReactPlayer from "react-player";

function OurServices() {
  const playerRef = useRef(null);
  return (
    <>
      <section className={styles.bannerContainer}>
        <div className={`${styles.bannerimg} ${styles.pcShow}`}>
          <img src={bannerpc} />
        </div>
        <div className={`${styles.bannerimg} ${styles.mobileShow}`}>
          <img src={bannermobile} />
        </div>
        <div className={styles.bannerText}>
          <h1>
            NEXT-GEN SKILL COURSES AT EVOSKILL LAB
            <br /> <span>Learning through Doing</span>
          </h1>
        </div>
        <div className={styles.backarrowClick}>
          <img src={backArrow} />
        </div>
      </section>
      <section className={styles.whatwedoSection}>
        <h2 className={`${styles.sectionHeading} ${styles.pl10}`}>
          What we do
        </h2>
        <div className={styles.mobileauto}>
          <div className={styles.whatwedoCardListing}>
            {whatwedo?.map((info, index) => {
              return <EvoCardWWD cardData={info} />;
            })}
          </div>
        </div>
        {/* <EvoCardWWD /> */}
      </section>
      <section className={styles.videoSection}>
        <h2 className={`${styles.sectionHeading} ${styles.pl10}`}>
          Experience our Immersive Lab
        </h2>
        <div className={styles.videoContainer}>
          <ReactPlayer
            controls={true}
            ref={(player) => {
              playerRef.current = player;
            }}
            // url={selectedVideo?.file}
            url="https://youtu.be/kxF-7NECgio?si=cz7mqceKQ8JUPSb7"
            // width="100%"
            // height="100%"
            borderRadius="25px"
            className={styles.innerVideoDisplay}
            style={{
              // margin: "50px auto",
              backgroundColor: "#000",
              borderRadius:"25px"
            }}
          />
        </div>
      </section>
      <section className={styles.coursesSection}>
        <h2 className={`${styles.sectionHeading} ${styles.textcenter}`}>
          Courses Available
        </h2>
        <div className={styles.cardlisting}>
          {coursesData.map((v) => {
            return <TextimgCard values={v} />;
          })}
        </div>
      </section>
      <section className={styles.toolsSection}>
        <div className={styles.border}>
          <h2 className={`${styles.sectionHeading} ${styles.textcenter} `}>
            Tools Learnt
          </h2>
          <div className={`${styles.cardlisting} `}>
            {toolsData.map((v) => {
              return <TextimgCard values={v} />;
            })}
          </div>
        </div>
      </section>
      <section className={styles.applynowSection}>
        <div className={styles.applynowtext}>
          <p>
            Become a STEM teacher with us and educate the next-generation,
            paving the way for a skilled and empowered workforce of the future.
          </p>
          <div>
            <Button variant="contained" className={styles.applynowbtn}>
              Apply Now
            </Button>
          </div>
        </div>
      </section>
      <section className={styles.whyus}>
        <h2 className={`${styles.sectionHeading} ${styles.textcenter} `}>
          Why Us
        </h2>
        <h4 className={`${styles.highlighttext} ${styles.textcenter} `}>
          We offer STEM Education + 21st Century Skills
        </h4>
        <p className={`${styles.normalpara} ${styles.textcenter} `}>
          We focus on developing psychological and cognitive skills that enhance
          a student's abilities, aptitude, and areas of interest. STEM education
          is the pathway for students to explore and receive practical hands-on
          training, allowing them to learn next-generation career subjects and
          their real-life applications.
        </p>
        <p className={`${styles.heghlightpara} ${styles.textcenter} `}>
          At BrainHap, our learning approach is achieved through strategic
          planning, critical thinking, teamwork, and decision-making.
        </p>
        <LeftRightcomponent Data={whyusdata} Color="#11898A" />
      </section>

      <section className={styles.outcomeSection}>
        <h2 className={`${styles.sectionHeading} ${styles.textcenter} `}>
          Why Us
        </h2>
        <div className={`${styles.outcomeimgContainer}`}>
          <img src={outcome} alt="" />
        </div>
      </section>

      <ExplorMore exploredataobj={exploreobje} />

      <section className={styles.buyOurkitsection}>
        <h2 className={`${styles.sectionHeading} ${styles.textcenter} `}>
          Buy our kit
        </h2>
        <div className={styles.buycardListing}>
          {buycard?.map((v) => {
            return <BuyKitCard values={v} />;
          })}
        </div>

        <div className={`${styles.LastText} ${styles.textcenter} `}>
          <h4 className={styles.buynowLastText}>
            Haven’t connected BrainHap yet?
          </h4>
          <Button variant="contained" className={styles.coonectbtn}>
            Connect Now
          </Button>
        </div>
      </section>
    </>
  );
}

export default OurServices;

const exploreobje = {
  imgOne: StemAccredition,
  textOne: "CURRICULUM DESIGN YOU CAN TRUST",
  imgTwo: "",
  textTwo: "",
};

const whatwedo = [
  {
    id: 1,
    img: wwdimg1,
    backgroundCol:"linear-gradient(180deg, #DBFEF4 0%, #BFE1E0 100%)",
    para: "Age-appropriate vocational skill courses focusing on next-gen careers.",
  },
  {
    id: 2,
    img: wwdimg2,
    backgroundCol:"linear-gradient(359deg, #C8E1C5 1.21%, #DDEBDC 99.36%)",
    para: "Carefully designed, formulated and structured content to bring out the best.",
  },
  {
    id: 3,
    img: wwdimg3,
    backgroundCol:"linear-gradient(180deg, #FBF4F4 0%, #F6E8E8 100%)",
    para: "Build 21st century skills like Critical Thinking, Logical Reasoning, Problem Solving and many more.",
  },
  {
    id: 4,
    img: wwdimg4,
    backgroundCol:"linear-gradient(180deg, #EDF3F9 0%, #D5E2F0 99.62%)",
    para: "Increasing student probability of achieving dream career.",
  },
  {
    id: 5,
    img: wwdimg5,
    backgroundCol:" linear-gradient(180deg, #EBEFF3 0%, #C8DBE2 98.84%)",
    para: "Experiential learning courses in accordance with NEP 2020.",
  },
];

const coursesData = [
  {
    id: 1,
    img: course1,
    title: "Coding",
  },
  {
    id: 2,
    img: course2,
    title: "Robotics",
  },
  {
    id: 3,
    img: course3,
    title: "Drone",
  },
  {
    id: 4,
    img: course4,
    title: "Python",
  },
  {
    id: 5,
    img: course5,
    title: "AR/VR",
  },
  {
    id: 6,
    img: course6,
    title: "HTML",
  },
  {
    id: 7,
    img: course7,
    title: "Electronics",
  },
  {
    id: 8,
    img: course8,
    title: "3D design and Printing",
  },
];

const toolsData = [
  {
    id: 1,
    img: tools1,
    title: "PictoBlox",
  },
  {
    id: 2,
    img: tools2,
    title: "Notepad ++",
  },
  {
    id: 3,
    img: tools3,
    title: "Oneshape",
  },
  {
    id: 4,
    img: tools4,
    title: "PytMIT APP Inventorhon",
  },
  {
    id: 5,
    img: tools5,
    title: "Jupyter",
  },
  {
    id: 6,
    img: tools6,
    title: "Unity",
  },
];

const whyusdata = [
  {
    id: 1,
    img: whyus1,
    heading: "Our STEM fundamentals activities lay the groundwork for:",
    para: "Neuroscience studies have shown that psychological and cognitive skills enhancement increase the chances of academic success.",
  },
  {
    id: 2,
    img: whyus2,
    heading: "Next-Gen Problem Solvers: Problem-Based Learning (PBL)",
    para: "Our approach is centered on problem-based learning because it nurtures adaptable thinking – a skill crucial for future success. In this learning journey, every question is a stepping stone, and we are equipped with all the resources to guide you through simulations, missions, and problem-solving experiences.",
  },
];

const buycard = [
  {
    id: 1,
    img: buykitimg,
    title: "Kit No. 1",
    desc: "Map fields in minutes to conduct stand counts, identify variability",
  },
  {
    id: 2,
    img: buykitimg,
    title: "Kit No. 1",
    desc: "Map fields in minutes to conduct stand counts, identify variability",
  },
  {
    id: 3,
    img: buykitimg,
    title: "Kit No. 1",
    desc: "Map fields in minutes to conduct stand counts, identify variability",
  },
  {
    id: 4,
    img: buykitimg,
    title: "Kit No. 1",
    desc: "Map fields in minutes to conduct stand counts, identify variability",
  },
];
